<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                title="阅读记录"
                class="header"
                z-index="99"
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
            <template #right>
                <p class="head-right" @click="delClick">清空</p>
            </template>
        </van-nav-bar>
        <div>
            <van-pull-refresh v-model="refreshing" success-text="刷新成功" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :immediate-check="false"
                        :finished-text="finished_text"
                        @load="onLoad"
                >
                    <template v-for="(item,index) in list">
                        <van-swipe-cell>
                            <div class="div record-list">
                                <div class="record-left" @click="readingClick(item)">
                                    <p class="points_relative">
                                        <img :src="item.coverpic" alt="" class="book_img">
                                        <span class="points_vip" v-if="item.isvip =='1'">VIP</span>
                                    </p>
                                    <div class="title-right">
                                        <p class="title">{{item.title}}</p>
                                        <p class="subtitle">
                                            <img src="../../assets/images/author_icon.png" alt="" class="author_icon">
                                            <span>{{item.author}}</span>
                                        </p>
                                        <p class="time">{{item.created_at}}</p>
                                    </div>
                                </div>
                                <p class="record-right" v-if="item.is_shelve == '0'" @click="shelve(item,index)">
                                    <img src="../../assets/images/phone4.png" alt="" class="phone4">
                                    书架</p>
                                <p class="record-right record-active" v-else>
                                    <img src="../../assets/images/account_sel4.png" alt="" class="account_sel4">
                                    书架</p>
                            </div>
                            <template #right>
                                <van-button square text="删除" type="danger" class="delete-button"
                                            @click="beforeClose(item)"/>
                            </template>
                        </van-swipe-cell>
                    </template>
                    <template v-if="list.length == 0">
                        <div class="no">
                            <No title="暂无阅读记录，快去看书吧！"></No>
                        </div>
                    </template>
                </van-list>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    import No from '../../components/No'

    export default {
        components: {
            No
        },
        name: "Record",
        data() {
            return {
                page: 1,
                page_size: 10,
                refreshing: false,
                loading: false,
                finished: false,
                finished_text: '',
                count: 0,
                list: [],
                list_show: true,
            }
        },
        created() {
            this.read_history()
        },
        methods: {
            readingClick(item) {
                this.$router.push({
                    path: '/reading',
                    query: {
                        id: String(item.anid),
                        chaps: String(item.chapter.chaps)
                    }
                })
            },
            onRefresh() {
                //下拉刷新
                this.page = 1
                if (this.list.length >= this.count) {
                    this.finished = true
                } else {
                    this.finished = false
                }
                this.list_show = true
                this.read_history()
            },
            onLoad() {
                if (this.finished) return
                this.page++;
                this.read_history();
            },

            delClick() {
                if (this.list.length == 0) return
                this.$dialog.alert({
                    title: '清空',
                    message: '确定要清空阅读记录吗？',
                    confirmButtonColor: '#4D77FD',
                    cancelButtonColor: '#606266',
                    showCancelButton: true,
                }).then(() => {
                    this.$axios.post('/api/bookstores/empty_read_history')
                        .then(res => {
                            this.$toast(res.data.message);
                            this.list = []
                        })
                })
                    .catch(() => {
                    })

            },
            // 加入书架
            shelve(item, index) {
                this.$axios.get('/api/bookstores/add_to_shelve?anime_id=' + item.anid)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$toast(res.data.message);
                            this.list[index].is_shelve = '1'
                        }
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },

            read_history() {
                this.$axios.get('/api/bookstores/read_history', {
                    params: {
                        page: this.page,
                        page_size: this.page_size
                    }
                })
                    .then(res => {
                        var res = res.data.data
                        this.refreshing = false
                        this.loading = false;
                        this.count = res.count
                        if (this.list_show) {
                            this.list = res.list
                            this.list_show = false
                        } else {
                            this.list = this.list.concat(res.list)
                        }
                        if (this.list.length >= this.count) {
                            this.finished = true
                            this.finished_text = '没有更多了'
                        } else {
                            this.finished = false
                        }
                    })
            },
            beforeClose(item) {
                this.$dialog.alert({
                    title: '删除',
                    message: '确定删除吗？',
                    showCancelButton: true,
                }).then(() => {
                    this.$axios.get('/api/bookstores/delete_read_history', {
                        params: {
                            id: item.anid
                        }
                    })
                        .then(res => {
                            this.$toast(res.data.message);
                            this.list_show = true
                            this.read_history()
                        })
                })
                    .catch(() => {
                    })
            },
        }
    }
</script>

<style scoped>
    .record-active {
        border: 1px solid rgba(237, 239, 242, 1) !important;
        color: #BFC2CC !important;
    }

    .account_sel4 {
        width: 16px;
        height: 11px;
        margin-right: 5px;
    }

    .record-right {
        padding: 15px 15px;
        height: 34px;
        border: 1px solid rgba(77, 119, 253, 1);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        color: rgba(77, 119, 253, 1);
    }

    .record-list {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 40px;
    }

    .record-left {
        display: flex;
        align-items: center;
    }

    .title-right {
        width: 350px;
        margin-left: 28px;
    }

    .title {
        color: #303133;
        font-size: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .subtitle {
        color: #909399;
        font-size: 24px;
        display: flex;
        align-items: center;
        margin: 22px 0 26px 0;
    }

    .subtitle > span {
        width: 300px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .time {
        color: #909399;
        font-size: 22px;
    }

    .phone4 {
        width: 16px;
        height: 16px;
        margin-right: 5px;
    }

    .author_icon {
        width: 23px;
        height: 27px;
        margin-right: 10px;
    }

    .book_img {
        width: 100px;
        height: 140px;
        background: url("../../assets/images/book_cover_def.png") no-repeat;
        background-size: cover;
        border-radius: 10px;
    }

    .delete-button {
        height: 100%;
    }

    .head-right {
        font-size: 28px;
        color: #606266;
    }
</style>